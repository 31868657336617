// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-root {
  height: 100vh;
  overflow: hidden;
}

.modal--mask {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9998;
}

.modal-window {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  min-width: 50%;
}

.modal--header,
.modal--body,
.modal--footer {
  margin: 0;
  padding: 1rem;
}

.modal--header h1 {
  margin: 0;
  padding: 0;
}

.modal--footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}

code {
  color: rgb(255, 0, 0);
  font-size: 1rem;
  font-weight: 600;
}

button {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,SAAS;EACT,QAAQ;EACR,OAAO;EACP,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,cAAc;AAChB;;AAEA;;;EAGE,SAAS;EACT,aAAa;AACf;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n#app-root {\n  height: 100vh;\n  overflow: hidden;\n}\n\n.modal--mask {\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  z-index: 9998;\n}\n\n.modal-window {\n  background-color: rgb(255, 255, 255);\n  box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);\n  min-width: 50%;\n}\n\n.modal--header,\n.modal--body,\n.modal--footer {\n  margin: 0;\n  padding: 1rem;\n}\n\n.modal--header h1 {\n  margin: 0;\n  padding: 0;\n}\n\n.modal--footer {\n  display: flex;\n  justify-content: flex-end;\n  column-gap: 1rem;\n}\n\ncode {\n  color: rgb(255, 0, 0);\n  font-size: 1rem;\n  font-weight: 600;\n}\n\nbutton {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
