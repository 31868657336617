import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import AppShell from "./AppShell";
import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Providers } from "@microsoft/mgt-element";
import { HelmetProvider } from "react-helmet-async";
import "./main.css";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,  // This is the ONLY mandatory field that you need to supply.
    authority: process.env.REACT_APP_AUTHORITY,  // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: process.env.REACT_APP_REDIRECT_URI, //,"http://localhost:3000/",//Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    scopes: [
      "calendars.read",
      "user.read",
      "openid",
      "profile",
      "people.read",
      "user.readbasic.all",
    ],
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance,
});
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback(
  (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  },
  (error) => {
    console.log("error", error);
  }
);

export default msalInstance;

ReactDOM.render(
  <StrictMode>
     <HelmetProvider>
    <AppShell />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("app-root")
);
