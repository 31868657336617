import React, { useEffect, useState } from 'react';
import Metadata from "./components/page/Metadata";
import Userdetails from "./components/page/Userdetails";

const ModalManager = ({
  values,
  pbuDetail,
  vgrpDetail,
  projAsis,
  projMem,
  vgrpVal,
  pbuVal,
}) => {

  //const [fromCRMprojid,setfromCRMprojid]=useState('');
  const [radiovalue, setRadiovalue] = useState("CRM");
  const [step, setStep] = useState(1);
  const [searchVal, setSearchVal] = useState("");
 
  // go back to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  /*const closeFn = () => {
  //clear all fields on close
  if(fromCRM || fromMAC){
    window.location.replace(process.env.REACT_APP_REDIRECT_URI);
    //window.location.replace("http://localhost:3000/");
  }else{
    //clear fields when close
    pbuDetail.splice(0, pbuDetail.length);
    vgrpDetail.splice(0, vgrpDetail.length);
    projAsis.splice(0, projAsis.length);
    projMem.splice(0, projMem.length);
    vgrpVal.splice(0, vgrpVal.length);
    pbuVal.splice(0, pbuVal.length);
    setRadiovalue("CRM");
    setStep(1);
    setSearchVal("");
  }
 
  };*/
    
  return (
    <>
              {step === 1 ? (
                <Metadata
                  nextStep={nextStep} // action on next button click
                  values={values} //Values to be carryed out all the screen
                 // closeFn={closeFn} //Close function
                  pbuDetail={pbuDetail} //Carry out PBU values from API call
                  vgrpDetail={vgrpDetail} //Carry out viewer group values API call
                  projAsis={projAsis} //Set project assistant variable
                  projMem={projMem} //Set Member variables
                  vgrpVal={vgrpVal} //Set Viewer group variables
                  pbuVal={pbuVal} //Set PBU variable that selected from project
                  radiovalue={radiovalue} //set radio button selection
                  setRadiovalue={setRadiovalue} //Check for radio button
                  searchVal={searchVal} //Set search value
                  setSearchVal={setSearchVal} //Get searched value
                  //fromCRM={fromCRM} //Check if it's from CRM project
                  //fromCRMisProj={fromCRMisProj}//Check if it's from CRM project if express
                  //metadataVal ={metadataVal} //Get all metadata values
                  //fromMAC={fromMAC}
                />
              ) : (
                ""
              )}
              {step === 2 ? (
                <Userdetails
                  prevStep={prevStep} //Action when previous button click
                  nextStep={nextStep} // action on next button click
                  values={values} //Values to be carryed out all the screen
                  //closeFn={closeFn} //Close function
                  pbuDetail={pbuDetail} //Carry out PBU values from API call
                  vgrpDetail={vgrpDetail} //Carry out viewer group values API call
                  projAsis={projAsis} //Set project assistant variable
                  projMem={projMem} //Set Member variables
                  vgrpVal={vgrpVal} //Set Viewer group variables
                  pbuVal={pbuVal} //Set PBU variable that selected from project
                  radiovalue={radiovalue} //set radio button selection
                  setRadiovalue={setRadiovalue} //Check for radio button
                  searchVal={searchVal} //Set search value
                  setSearchVal={setSearchVal} //Get searched value
                />
              ) : (
                ""
              )}
    </>
  );
};

export default ModalManager;


