import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppHeader from "./AppHeader";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import msalInstance from "./index";
import { LanguageProvider } from "./LanguageContext";
import { Helmet } from "react-helmet-async";
const AppShell = () => {
  //const [modalOpen, setModal] = useState(false);

  //Setup the initial values for the PBU, Viewer Group
  const [pbuDetail, setpbuDetail] = useState([]);
  const [vgrpDetail, setvgrpDetail] = useState([]);

  //Cache values between forms PBU, Viewer Group, Project Assistant, Project Member
  const [projAsis, setprojAsis] = useState([]);
  const [projMem, setprojMem] = useState([]);
  const [vgrpVal, setvgrpVal] = useState([]);
  const [pbuVal, setpbuVal] = useState([]);
 
  // eslint-disable-next-line
  const [
    {
      prname,
      prnameEng,
      crmNo,
      macNo,
      client,
      clientID,
      pjMan,
      pjOwn,
      pjManDet,
      pjOwnDet,
      resDept,
      pbu,
      pbuDescription,
      mar,
      geo,
      submitaltype,
      fileryId,
      pjTemp,
      pjAssis,
      pjMem,
      vrGrp,
      resDeptNo,
      resDeptName,
      resDeptCode,
      bbID,
      crmExpressProjectNumber,
      streamId,
      expectedProjectStartDate,
      expectedProjectEndDate,
      projectLevelRating,
      projectCreatedFrom,
      macfileryurl,
      prstage,
    },
    setVal,
  ] = useState("");

  //Variable for Modern popup window
  /*const openModal = (event) => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal },
      },
    } = event;
    if (modal) setModal(modal);
  };*/

  
  // metadata to send for the required fields to other components
  const values = {
    prname, // project name variable
    prnameEng, // project name english variable
    crmNo, // project CRM number variable
    macNo, // project Maconomy number variable
    client, // project client variable
    clientID, // project client ID variable
    pjMan, // project manager variable
    pjOwn, // project owner variable
    pjManDet,// project manager details for db update
    pjOwnDet,// project owner details for db update
    resDept, // project resoisible department full name variable
    pbu, // project PBU variable
    pbuDescription, // project PBU description variable
    mar, // project market variable
    geo, // project geography variable
    submitaltype, // type of submmition
    fileryId, // Project ID
    pjTemp, // Project template variable
    pjAssis, // Project assistant variable
    pjMem, // Project member variable
    vrGrp, // Project viewer group variable
    resDeptNo, // Project responsible department number variable
    resDeptName, // Project responsible department name variable
    resDeptCode, // Project responsible department code variable
    bbID, // Project buidling block Id variable
    crmExpressProjectNumber, // express project number variable
    streamId, // Project stream variable
    expectedProjectStartDate, //comes from CRM(we have these field in CRM)
    expectedProjectEndDate, //comes form CRM(we have these field in CRM)
    projectLevelRating,//comes form CRM(we have these field in CRM)    
    projectCreatedFrom,//to identify project created date time
    macfileryurl,//to get existing filery url 
    prstage,//to get project stage value
  };

  return (
    /*SSO Authenticated*/
    <LanguageProvider>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`                    
                      script-src 'self';
                      connect-src https://login.microsoftonline.com https://filery-api-uat.azurewebsites.net https://graph.microsoft.com;
                `}
        ></meta>
        <title>Filery StartUp</title>
      </Helmet>
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <BrowserRouter>         
              <AppHeader
                  values={values}
                  pbuDetail={pbuDetail}
                  vgrpDetail={vgrpDetail}
                  projAsis={projAsis}
                  projMem={projMem}
                  vgrpVal={vgrpVal}
                  pbuVal={pbuVal}
              />
          </BrowserRouter>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </div>
    </LanguageProvider>
  );
};

export default AppShell;

