import React, { useState, useEffect, useRef } from "react";
import "../common/styles/styles.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "bootstrap/dist/css/bootstrap.min.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import Select from "react-select";
import Modal from "../common/modal/Modal";
import Alert from "@mui/joy/Alert";
import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Warning from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import IconButton from "@mui/joy/IconButton";
import Check from "@mui/icons-material/Check";
import AspectRatio from "@mui/joy/AspectRatio";
import LinearProgress from "@mui/joy/LinearProgress";

//Set theme properties for the screen reader
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#009de0 !important" : "#009de0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  boxShadow: "none",
  fontFamily: "GothamRounded-Book",
}));

const Userdetails = ({
  prevStep,
  values,
  //closeFn = () => null,
  pbuDetail,
  vgrpDetail,
  projAsis,
  projMem,
  vgrpVal,
  pbuVal,
}) => {
  // Create variables for the screen reader
  // eslint-disable-next-line
  const [selectedPBUValue, setSelectedPBUValue] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const pplrefPjMem = useRef();
  const pplrefPjAsis = useRef();
  const vrgpref = useRef();
  const { instance, accounts } = useMsal();
  const [projAsisDetail, setprojAsisDetail] = useState([]);
  const [projMemDetail, setprojMemDetail] = useState([]);
  const [selectedProjMem, setSelectedProjMem] = useState(projMem || []); // State for selected values
  const [isWarn, setisWarn] = useState(false);
  const [isWarnMsg, setisWarnMsg] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [isSuccessMsg, setisSuccessMsg] = useState("");
  const [isLoading, setisLoading] = useState(false);
  let curentUserId = "";
  const accessTokenRequestGraphAPI = {
    scopes: [process.env.REACT_APP_GRAPH_SCOPES],
    account: accounts[0],
  };

  // get all viwers group from DB
  const tokenType = process.env.REACT_APP_TOKENTYPE;

  //Authentication options for API requests
  const accessTokenRequest = {
    scopes: [process.env.REACT_APP_SCOPES],
    account: accounts[0],
  };

  //Get selcted values from the PBU template
  const handlePBUChange = (event) => {
    setSelectedPBUValue(event.value);
    pbuVal.splice(0, pbuVal.length);
    pbuVal.push({
      value: event.value,
      label: event.label,
    });
  };

  //For people picker authtication methods
  useEffect(() => {
    console.log(
      "isAuthenticated, inProgress: ",
      [isAuthenticated, inProgress],
      [typeof isAuthenticated, typeof inProgress]
    );
    Providers.globalProvider.setState(
      inProgress !== "none"
        ? ProviderState.Loading
        : isAuthenticated
        ? ProviderState.SignedIn
        : ProviderState.SignedOut
    );
  }, [isAuthenticated, inProgress]);

  //Handler for Viewer Group
  const vrGphandleSelectionChanged = (values) => {
    if (values != null) {
      vgrpVal.splice(0, vgrpVal.length);
      for (let vrgpcount = 0; vrgpcount < values.length; vrgpcount++) {
        vgrpVal.push({
          value: values[vrgpcount].value,
          label: values[vrgpcount].label,
          id: values[vrgpcount].id,
        });
      }
    }
    vrgpref.current.controlRef.style =
      "min-height:38px;max-height:80px;overflow:auto";
  };

  //handle close event handler for alert
  const handleCloseWarn = () => {
    setisWarn(false);
    setisWarnMsg("");
  };
  const handleCloseSuccess = () => {
    setisSuccess(false);
    setisSuccessMsg("");
    window.location.replace(process.env.REACT_APP_REDIRECT_URI);
  };

  //Handler for Project Member ppl picker
  const projMemhandleSelectionChanged = (values) => {
    if (values != null) {
      if (values.length <= 90) {
        let updatedProjMem = values.map((item) => ({
          value: item.value,
          label: item.label,
        }));
        setSelectedProjMem(updatedProjMem); // Ensure state updates
        projMem.length = 0; // Clear existing projMem reference
        //projMem.push(...updatedProjMem); // Push new values
       // projMem.splice(0, projMem.length);
        for (
          let projMemcount = 0;
          projMemcount < values.length;
          projMemcount++
        ) {
          projMem.push({
            value: values[projMemcount].value,
            label: values[projMemcount].label,
          });
        }
      } else {
        values.pop(values[values.length]);
        setisWarn(true);
        setisWarnMsg(process.env.REACT_APP_WARNINGMSG_PPLPICKERMAXVALUE);
      }
    }
  };

   // Clear all selections
  const clearAllSelections = () => {
    setSelectedProjMem([]); // Clears selected values 
    projMem.length = 0; // Clears projMem as well
  };

  async function getCurrentUserId() {
    await instance
      .acquireTokenSilent(accessTokenRequestGraphAPI)
      .then((accessTokenResponse) => {
        //Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        //Call your API with token
        const headers = {
          Authorization: process.env.REACT_APP_TOKENTYPE + accessToken,
        };
        fetch("https://graph.microsoft.com/v1.0/me?$select=id", { headers })
          .then(function (response) {
            return response.json();
          })
          .then((currUserResp) => {
            curentUserId = currUserResp.id;
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  //Handler for Project Assistant ppl picker
  const projAsishandleSelectionChanged = (values) => {
    if (values != null) {
      if (values.length <= 90) {
        projAsis.splice(0, projAsis.length);
        for (
          let projAsiscount = 0;
          projAsiscount < values.length;
          projAsiscount++
        ) {
          projAsis.push({
            value: values[projAsiscount].value,
            label: values[projAsiscount].label,
          });
        }
      } else {
        values.pop(values[values.length]);
        setisWarn(true);
        setisWarnMsg(process.env.REACT_APP_WARNINGMSG_PPLPICKERMAXVALUE);
      }
    }
  };

  useEffect(() => {
    pplrefPjAsis.current.controlRef.style =
      "min-height:38px;max-height:80px;overflow:auto";
    pplrefPjMem.current.controlRef.style =
      "min-height:38px;max-height:80px;overflow:auto";
    vrgpref.current.controlRef.style =
      "min-height:38px;max-height:80px;overflow:auto";
  }, [pplrefPjAsis, pplrefPjMem, vrgpref]);

  //Handler to fetch Project Member ppl picker users
  const projMemhandleChanged = (value) => {
    // if (value !== "") {
    //   value = value.replace("&", "%26");
    //   setSelectedProjMem(values || []); 
    if (!value || typeof value !== "string") return; // Ensure value is valid before processing
    value = value.replace("&", "%26"); // Now safe to replace

    //setSelectedProjMem([]); // Clears selected values when input changes
      instance
        .acquireTokenSilent(accessTokenRequestGraphAPI)
        .then((accessTokenResponse) => {
          //Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          //Call your API with token
          const headers = {
            Authorization: process.env.REACT_APP_TOKENTYPE + accessToken,
          };
          fetch(
            "https://graph.microsoft.com/v1.0/users?$select=userType,displayName,mail,userPrincipalName,id&$filter=userType eq 'member' and (startswith(displayName,'" +
              value +
              "') or startswith(mail,'" +
              value +
              "') or startswith(userPrincipalName,'" +
              value +
              "') or startswith(givenName,'" +
              value +
              "') or startswith(surname,'" +
              value +
              "'))",
            { headers }
          )
            .then(function (response) {
              return response.json();
            })
            .then((projMemValue) => {
              let projMemDet = [];
              for (
                let projMemCount = 0;
                projMemCount < projMemValue.value.length;
                projMemCount++
              ) {
                if (projMemValue.value[projMemCount].mail !== null) {
                  projMemDet.push({
                    value:
                      projMemValue.value[projMemCount].displayName +
                      "|" +
                      projMemValue.value[projMemCount].mail +
                      "|" +
                      projMemValue.value[projMemCount].id,
                    label:
                      projMemValue.value[projMemCount].displayName +
                      " - " +
                      projMemValue.value[projMemCount].mail,
                  });
                } else {
                  projMemDet.push({
                    value:
                      projMemValue.value[projMemCount].displayName +
                      "|" +
                      projMemValue.value[projMemCount].userPrincipalName +
                      "|" +
                      projMemValue.value[projMemCount].id,
                    label:
                      projMemValue.value[projMemCount].displayName +
                      " - " +
                      projMemValue.value[projMemCount].userPrincipalName,
                  });
                }
              }
              setprojMemDetail(projMemDet);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    //}
    
  };

  //Handler to fetch Project Assistant ppl picker users
  const projAsishandleChanged = (value) => {
    if (value !== "") {
      value = value.replace("&", "%26");
      instance
        .acquireTokenSilent(accessTokenRequestGraphAPI)
        .then((accessTokenResponse) => {
          //Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          //Call your API with token
          const headers = {
            Authorization: process.env.REACT_APP_TOKENTYPE + accessToken,
          };
          fetch(
            "https://graph.microsoft.com/v1.0/users?$select=userType,displayName,mail,userPrincipalName,id&$filter=userType eq 'member' and (startswith(displayName,'" +
              value +
              "') or startswith(mail,'" +
              value +
              "') or startswith(userPrincipalName,'" +
              value +
              "') or startswith(givenName,'" +
              value +
              "') or startswith(surname,'" +
              value +
              "'))",
            { headers }
          )
            .then(function (response) {
              return response.json();
            })
            .then((projAsisValue) => {
              let projAsisDet = [];
              for (
                let projAsisCount = 0;
                projAsisCount < projAsisValue.value.length;
                projAsisCount++
              ) {
                if (projAsisValue.value[projAsisCount].mail !== null) {
                  projAsisDet.push({
                    value:
                      projAsisValue.value[projAsisCount].displayName +
                      "|" +
                      projAsisValue.value[projAsisCount].mail +
                      "|" +
                      projAsisValue.value[projAsisCount].id,
                    label:
                      projAsisValue.value[projAsisCount].displayName +
                      " - " +
                      projAsisValue.value[projAsisCount].mail,
                  });
                } else {
                  projAsisDet.push({
                    value:
                      projAsisValue.value[projAsisCount].displayName +
                      "|" +
                      projAsisValue.value[projAsisCount].userPrincipalName +
                      "|" +
                      projAsisValue.value[projAsisCount].id,
                    label:
                      projAsisValue.value[projAsisCount].displayName +
                      " - " +
                      projAsisValue.value[projAsisCount].userPrincipalName,
                  });
                }
              }
              setprojAsisDetail(projAsisDet);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Handles event for back button selection
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const formatOptionLabel = ({ label }, { inputValue }) => {
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  //Handle event for submitting data form from
  const handleSubmit = (event) => {
    setisLoading(true);
    getCurrentUserId();
    let accessToken;
    let vrgpStr = "";
    values.macNo =
      values.macNo === "No information available yet" ? "" : values.macNo;
    values.crmNo =
      values.crmNo === "No information available yet" ? "" : values.crmNo;
    const curentDateTime = new Date().toISOString().replace("Z", "");
    let projUsersArr = [];
    if (projAsis.length > 0) {
      for (
        let projAsiscount = 0;
        projAsiscount < projAsis.length;
        projAsiscount++
      ) {
        projUsersArr.push({
          roleName: "Project Assistant",
          displayName: projAsis[projAsiscount].value.split("|")[0],
          loginName: projAsis[projAsiscount].value.split("|")[1],
          email: projAsis[projAsiscount].value.split("|")[1],
          aDid: projAsis[projAsiscount].value.split("|")[2],
        });
      }
    }
    if (projMem.length > 0) {
      for (
        let projMemcount = 0;
        projMemcount < projMem.length;
        projMemcount++
      ) {
        projUsersArr.push({
          roleName: "Project Member",
          displayName: projMem[projMemcount].value.split("|")[0],
          loginName: projMem[projMemcount].value.split("|")[1],
          email: projMem[projMemcount].value.split("|")[1],
          aDid: projMem[projMemcount].value.split("|")[2],
        });
      }
    }

    if (values.pjManDet !== "") {
      projUsersArr.push({
        roleName: "Project Manager",
        displayName: values.pjManDet.split("|")[0],
        loginName: values.pjManDet.split("|")[1],
        email: values.pjManDet.split("|")[1],
        aDid: values.pjManDet.split("|")[2],
      });
    }
    if (values.pjOwnDet !== "") {
      projUsersArr.push({
        roleName: "Project Owner",
        displayName: values.pjOwnDet.split("|")[0],
        loginName: values.pjOwnDet.split("|")[1],
        email: values.pjOwnDet.split("|")[1],
        aDid: values.pjOwnDet.split("|")[2],
      });
    }
    if (vgrpVal.length > 0) {
      for (
        let vgrpValcount = 0;
        vgrpValcount < vgrpVal.length;
        vgrpValcount++
      ) {
        vrgpStr += vgrpVal[vgrpValcount].id + ",";
      }
      vrgpStr = vrgpStr.substring(0, vrgpStr.length - 1);
    }
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        //Acquire token silent success
        accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const headers = { Authorization: tokenType + accessToken };

        fetch(process.env.REACT_APP_API_GETUNIQUEID + values.pbu, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((fileryidresp) => {
            if (fileryidresp.message === "Success") {
              values.fileryId = fileryidresp.data[0].fileryId;
              console.log(values.fileryId);
              //Create varaiable to send for POST requests
              const saveMetadata = {
                uniqueID: values.fileryId,
                projectName_English: values.prnameEng,
                projectName: values.prname,
                maconomyProjectID: values.macNo,
                crmProjectID: values.crmNo,
                clientName: values.client,
                departmentNo: values.resDeptNo,
                departmentName: values.resDeptName,
                departmentCode: values.resDeptCode,
                pbu: values.pbu,
                pbuDescription: values.pbuDescription,
                market: values.mar,
                geography: values.geo,
                confidential: false,
                buildingBlockID: pbuVal[0].value,
                // datasourceName: null,
                // teamsID: null,
                // teamsLink: null,
                projectCreationDate: curentDateTime,
                // maconomyProjectClosureDate: null,
                // crmProjectClosureDate: null,
                // bA_Site_ID: null,
                // teamsCreationsStatus: null,
                // projectWiseCreationStatus: 1,
                // usermanagementStatus: null,
                // buildingBlockStatus: 1,
                // pwTabStatus: null,
                // projectConfTabStatus: 0,
                // supplFolderTabStatus: null,
                // notificationStatus: null,
                // creationCompleted: null,
                fileryURL: "/",
                streamID: values.streamId,
                clientID: values.clientID,
                projectStage: values.prstage,
                expectedProjectStartDate: values.expectedProjectStartDate,
                expectedProjectEndDate: values.expectedProjectEndDate,
                projectLevelRating: values.projectLevelRating,
                submittalType: values.submitaltype,
                //shortProjectName: shortprName,
                // pwLink: null,
                // pwEmailLink: null,
                // connectedProjectLink: null,
                // projectShareLink: null,
                // msTeamsChannelLink: null,
                // connectedProjectId: null,
                // projectShareId: null,
                // wsgProjectURL: null,
                projectCreatedFrom: values.projectCreatedFrom,
                // crmExpressProjectNumber: values.crmNo,
                // wsgUrl: null,
                // alt_Project_ID: null,
                // alt_Project_Name: null,
                // wsgVersion: null,
                created_By: curentUserId,
                modified_On: null,
                modified_By: null,
                projectUsers: JSON.parse(JSON.stringify(projUsersArr)),
                viewerGroupList: vrgpStr,
              };

              const inputContent = {
                method: "POST",
                headers: {
                  Authorization: tokenType + accessToken,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(saveMetadata),
              };

              fetch(process.env.REACT_APP_API_SAVEMETADATA, inputContent)
                .then(function (response) {
                  return response.json();
                })
                .then((metadataresp) => {
                  console.log(metadataresp.message);

                  if (metadataresp.message === "Success") {
                    //** commented as the func is being moved to API */
                    /*let crmType="";
                    if(values.projectCreatedFrom.indexOf('OPP')>-1){
                      crmType="Opportunity";
                    }else{
                      crmType="Project";
                    }
                    const filerytoCRMdata = {
                      crmNumber: values.crmNo,
                      type: crmType,
                      mac_fileryid: values.fileryId,
                      mac_fileryurl: "/",
                    };
                    const filerytoCRMinput = {
                      method: "PATCH",
                      headers: {
                        Authorization: tokenType + accessToken,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(filerytoCRMdata),
                    };
                    fetch(
                      process.env.REACT_APP_API_FILERYTOCRM,
                      filerytoCRMinput
                    )
                      .then(function (response) {
                        return response.json();
                      })
                      .then((filerytoCRMresp) => {
                        console.log(filerytoCRMresp.data);                       
                        if (filerytoCRMresp.data === "Success") {*/
                    setisLoading(false);
                    setisSuccess(true);
                    setisSuccessMsg(
                      process.env.REACT_APP_SUCCESSMSG_PROJECTCREATED
                    );
                    //** commented as the func is being moved to API */
                    /* } else {
                          setisLoading(false); 
                          setisWarn(true);
                          setisWarnMsg(
                            process.env
                              .REACT_APP_WARNINGMSG_ERROROCCURRED_FILERYTOCRM
                          );
                        }
                      }); */
                  } else {
                    setisLoading(false);
                    setisWarn(true);
                    setisWarnMsg(
                      process.env
                        .REACT_APP_WARNINGMSG_ERROROCCURRED_SAVEMETADATA
                    );
                  }
                });
            } else {
              setisLoading(false);
              setisWarn(true);
              setisWarnMsg(
                process.env.REACT_APP_WARNINGMSG_ERROROCCURRED_FILERYID
              );
            }
          })
          .catch((err) => {
            console.log(err);
            setisLoading(false);
            setisWarn(true);
            setisWarnMsg(process.env.REACT_APP_WARNINGMSG_ERROROCCURRED);
          });
      });
  };
  //UI elements that render  the page and components
  return (
    <div>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Box
              style={{ paddingBottom: "2%" }}
              sx={{
                display: "flex",
                //justifyContent: "start",
                m: 1,
                p: 1,
                fontSize: "25px",
                fontFamily: "GothamRounded-Medium !important",
              }}
            >
              {"Add information"}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                pl: 5,
                pt: 1,
              }}
            >
              <div className="alignUserInfo">
                <Item>
                  <div className="userDescriptions">
                    Add Project Assistants who can help manage permissions and
                    other project settings
                  </div>
                </Item>
                <Item>
                  <div className="userValues">
                    <div>
                      {/* People picker control  Project Assistant */}
                      <label className="userDetailsFieldtxt">
                        Project Assistants
                      </label>
                      <Select
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        isMulti
                        openMenuOnClick={false}
                        formatOptionLabel={formatOptionLabel}
                        closeMenuOnSelect={false}
                        placeholder="Search Ramboll Employee"
                        name="projAsisDetail"
                        options={projAsisDetail}
                        className="basic-multi-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            hover: "#009de0",
                          },
                        })}
                        onInputChange={projAsishandleChanged}
                        onChange={projAsishandleSelectionChanged}
                        defaultValue={projAsis}
                        ref={pplrefPjAsis}
                      />
                    </div>
                  </div>
                </Item>
              </div>
              <div className="alignUserInfo">
                <Item>
                  <div className="userDescriptions">
                    Add your Project Team Members now if they are known. You can
                    always add more later
                  </div>
                </Item>
                <Item>
                  <div className="userValues">
                    <div>
                      <div style={{display: 'flex'}}>
                        <lable className="userDetailsFieldtxt">
                          Project Team Members
                        </lable>
                        <div
                          style={{
                            marginLeft: "145px",
                            cursor: "pointer",
                            color: "white",
                            fontSize:"10px"
                          }}
                          onClick={clearAllSelections}
                        >
                          <strong>Clear all members</strong>
                        </div>
                      </div>
                      {/* People picker control project member */}

                      <Select
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                        isMulti
                        openMenuOnClick={false}
                        formatOptionLabel={formatOptionLabel}
                        closeMenuOnSelect={false}
                        placeholder="Search Ramboll Employee"
                        name="projMemDetail"
                        options={projMemDetail}
                        className="basic-multi-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            hover: "#009de0",
                          },
                        })}
                        onInputChange={projMemhandleChanged}
                        onChange={projMemhandleSelectionChanged}
                        value={selectedProjMem}
                        ref={pplrefPjMem}
                      />
                    </div>
                  </div>
                </Item>
              </div>
              <div className="alignUserInfo">
                <Item>
                  <div className="userDescriptions">
                    Keep the global standard folder structure or select a PBU
                    specific folder structure
                  </div>
                </Item>
                <Item>
                  <div className="userValues">
                    {/* PBU details */}
                    <label className="userDetailsFieldtxt">
                      Folder Structure
                    </label>
                    <Select
                      name="pbuDetail"
                      formatOptionLabel={formatOptionLabel}
                      options={pbuDetail}
                      className="basic-multi-select"
                      defaultValue={pbuVal}
                      value={selectedPBUValue.Select}
                      onChange={handlePBUChange}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          hover: "#009de0",
                        },
                      })}
                    />
                  </div>
                </Item>
              </div>
              <div className="alignUserInfo">
                <Item>
                  <div className="userDescriptions">
                    Add groups to give them view access
                  </div>
                </Item>
                <Item>
                  {" "}
                  <div className="userValues">
                    {/* Viewer group details */}
                    <lable className="userDetailsFieldtxt">Viewer Groups</lable>
                    <Select
                      isMulti
                      formatOptionLabel={formatOptionLabel}
                      menuShouldScrollIntoView={true}
                      closeMenuOnSelect={false}
                      placeholder="Select Viewer groups"
                      name="vgrpDetail"
                      options={vgrpDetail}
                      className="basic-multi-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          hover: "#009de0",
                        },
                      })}
                      onChange={vrGphandleSelectionChanged}
                      defaultValue={vgrpVal}
                      ref={vrgpref}
                    />
                  </div>
                </Item>
              </div>
            </Box>
          </Grid>
          <div className="metadata-container">
            <div className="button-container">
              <div className="btnAlignment">
                <button
                  className="btnDesign"
                  style={{ marginRight: "0.5%" }}
                  id="btnNextInfo"
                  onClick={Previous}
                >
                  Back
                </button>
                <button
                  className="btnDesign"
                  style={{ marginRight: "0.5%" }}
                  id="btnNextInfo"
                  onClick={handleSubmit}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </Grid>
        {/* Model for validation messages */}
        <Modal
          open={isWarn}
          onClose={handleCloseWarn}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <div className="modal--mask">
            <div>
              <Stack spacing={2} sx={{ maxWidth: 400 }}>
                <Alert
                  variant="soft"
                  color="danger"
                  invertedColors
                  startDecorator={
                    <CircularProgress size="lg">
                      <Warning
                        variant="soft"
                        style={{ color: "#A51818 !important" }}
                      />
                    </CircularProgress>
                  }
                  sx={{ alignItems: "flex-start", "--Alert-gap": "1rem" }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ mt: 1 }}>There was a problem.</Typography>
                    <br />
                    <p level="body3" style={{ textDecoration: "none" }}>
                      <p dangerouslySetInnerHTML={{ __html: isWarnMsg }}></p>
                    </p>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="soft"
                        size="sm"
                        style={{ border: "1px solid", borderRadius: "20px" }}
                        onClick={handleCloseWarn}
                      >
                        Okay
                      </Button>
                    </Box>
                  </Box>
                </Alert>
              </Stack>
            </div>
          </div>
        </Modal>
        {/* Model for Success message */}
        <Modal
          open={isSuccess}
          onClose={handleCloseSuccess}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <div className="modal--mask">
            <div>
              <Stack spacing={2} sx={{ maxWidth: 400 }}>
                <Alert
                  color="success"
                  variant="soft"
                  invertedColors
                  startDecorator={
                    <AspectRatio
                      variant="solid"
                      ratio="1"
                      sx={{
                        minWidth: 40,
                        borderRadius: "50%",
                        boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
                      }}
                    >
                      <div>
                        <Check fontSize="xl2" />
                      </div>
                    </AspectRatio>
                  }
                  endDecorator={
                    <IconButton
                      variant="plain"
                      sx={{
                        "--IconButton-size": "32px",
                        transform: "translate(0.5rem, -0.5rem)",
                      }}
                    ></IconButton>
                  }
                  sx={{ alignItems: "flex-start", overflow: "hidden" }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography level="title-lg">Success</Typography>
                    <br />
                    <p level="body3" style={{ textDecoration: "none" }}>
                      <p dangerouslySetInnerHTML={{ __html: isSuccessMsg }}></p>
                    </p>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="soft"
                        size="sm"
                        style={{ border: "1px solid", borderRadius: "20px" }}
                        onClick={handleCloseSuccess}
                      >
                        Okay
                      </Button>
                    </Box>
                    <LinearProgress
                      variant="soft"
                      value={40}
                      sx={(theme) => ({
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        color: `rgb(${theme.vars.palette.success.lightChannel} / 0.72)`,
                        "--LinearProgress-radius": "0px",
                      })}
                    />
                  </Box>
                </Alert>
              </Stack>
            </div>
          </div>
        </Modal>
        {/* Model for loader */}
        <Modal
          open={isLoading}
          onClose={handleCloseWarn}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <div className="modal--mask">
            <div>
              <Stack spacing={2} sx={{ maxWidth: 400 }}>
                <Alert
                  color="neutral"
                  variant="solid"
                  invertedColors
                  startDecorator={
                    <CircularProgress size="md"></CircularProgress>
                  }
                  sx={{ alignItems: "flex-start", "--Alert-gap": "1rem" }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ mt: 1 }}>Submitting...</Typography>
                  </Box>
                </Alert>
              </Stack>
            </div>
          </div>
        </Modal>
      </Box>
    </div>
  );
};
export default Userdetails;
