// LanguageContext.js

import React, { createContext, useContext, useState } from "react";

// Create a context for language
const LanguageContext = createContext();

// Language provider component
export function LanguageProvider({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default to English

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

// Custom hook to access the current language
export function useLanguage() {
  return useContext(LanguageContext);
}
