import React, { useState, useEffect }  from "react";
import { useMsal } from "@azure/msal-react";
//import { NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import Rambolllogo from "../src/components/common/img/Ramboll_Logo.png";
import RambolllogoColor from "../src/components/common/img/Rambolllogo.png";
import Welcome from "../src/components/common/img/Business integrity.svg";
import DowntimeIcon from "../src/components/common/img/Downtime icon.svg";
import ModalManager from "./ModalManager";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
 
const AppHeader = ({
  values,
  pbuDetail,
  vgrpDetail,
  projAsis,
  projMem,
  vgrpVal,
  pbuVal, 
}) => {

  const [isStartUpDisabled, setisStartUpDisabled] = useState(undefined);
  const { instance, accounts } = useMsal();
  //api to get filery configurations
  const apiFilerySettings = process.env.REACT_APP_API_GETFILERYSETTINGS;
  const tokenType = process.env.REACT_APP_TOKENTYPE;
  //Authentication options for API requests
  const accessTokenRequest = {
      scopes: [process.env.REACT_APP_SCOPES],
      account: accounts[0],
  };

  useEffect(() => {
    //get config values from api
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        //Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        //Call your API with token
        const headers = { Authorization: tokenType + accessToken };
        fetch(apiFilerySettings, { headers })
          .then(function (response) {
            return response.json();
          })
          .then((filerysettingsArr) => {
            if (filerysettingsArr.data !== "") {
              setisStartUpDisabled(filerysettingsArr.data[0].isStartUpDisabled);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div
      className="homepageStlye"
      style={{
        display: "flow-root",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Navbar variant="dark" className="navHeader">
        <a className="navbar-brand" href="/">
          <img
            src={Rambolllogo}
            className="logoRamboll"
            alt="logo"
            height="50rem"
            width="220rem"
          />
        </a>
      </Navbar>
      <div>
        <div className="divContentLeft">
          <Box>
          <Grid container>
       
            <label className="homePageTitle">Welcome to Filery Startup!</label>
            <br />
            <div className="homePageDescription">
              <lable>Filery is Ramboll's global document</lable>
              <br />
              <label>management system that we use to document</label>
              <br />
              <lable>our client projects.</lable>
            </div>
        
          <img src={Welcome} className="WelcomeImg" alt="logo" />
     
            <img
              src={RambolllogoColor}
              className="logoRamboll1"
              alt="logo"
              height="50rem"
              width="220rem"
            />
         
          </Grid>
          </Box>
          </div>
        <div className="divContentRight">
        { isStartUpDisabled ? (
        <Box>     
          <div class="projTitle">Create a Filery project</div>
              <div class="downtimeTxt">Systems under maintenance…</div>   
                  <img src={DowntimeIcon} className="DowntimeImg" alt="logo" />
                      <div class="downtimeMsg">
                        Our systems are currently undergoing scheduled maintenance. Filery projects cannot be created right now, but should be available shortly. Thanks for your patience.
                        </div>
        </Box>) : isStartUpDisabled !== undefined ?
        (<ModalManager
          values={values}
          pbuDetail={pbuDetail}
          vgrpDetail={vgrpDetail}
          projAsis={projAsis}
          projMem={projMem}
          vgrpVal={vgrpVal}
          pbuVal={pbuVal}
          />) : (<Box style={{ paddingBottom: "2%"}}
          sx={{
            display: "flex",
            //justifyContent: "start",
            m: 1,   
            p: 1,
            fontSize: "25px",
            fontFamily: "GothamRounded-Medium !important",
          }}
        >
          {"Create a Filery project"}
        </Box>) }
        </div>
      </div>

    </div>
  );
};

export default AppHeader;
